import React, { Suspense } from 'react';
import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from './utils/axios';
import MyContext from './utils/context';
import 'moment/locale/zh-cn';
import { Spin } from 'antd';
import './styles/App.less';
// import Login from './pages/launcher/Login';
import Launcher from './pages/launcher';
import store from './redux/store';

const Container = React.lazy(() => import('./layouts/Container'));

const App = () => {
  const [logged, setLogged] = useState(false);
  const [menu, setMenu] = useState([]);
  const [account, setAccount] = useState({});

  async function getMenu() {
    const { data } = await axios.post(
      'system/menuController/queryMenuByToken',
      {
        flag: 1,
      }
    );
    if (data.success) {
      setMenu(data.data.menu);
      setAccount(data.data.account);
    }
  }

  // useEffect(() => {
  //   if (logged) {
  //     getMenu();
  //   }
  // }, [logged]);

  useEffect(() => {
    if (menu.length) {
      setLogged(true);
    }
  }, [menu, account]);

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    if (token) {
      getMenu();
      // setLogged(true);
    }
    // console.log('process', process.env.REACT_APP_BASE_IMAGE_URL);
  }, []);

  return (
    <BrowserRouter basename="/">
      {!logged ? (
        // <Login />
        <Launcher />
      ) : (
        <Suspense
          fallback={
            <div className="spin-wrapper">
              <Spin />
            </div>
          }
        >
          <MyContext.Provider value={{ menu, account }}>
            <Provider store={store}>
              <Container />
            </Provider>
          </MyContext.Provider>
        </Suspense>
      )}
    </BrowserRouter>
  );
};

export default App;
