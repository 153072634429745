import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './Login';
import './index.less';
import { useEffect, useState } from 'react';
import { Spin } from 'antd';

export default function Launcher() {
  const navigate = useNavigate();
  const [token, setToken] = useState('');

  useEffect(() => {
    let token = sessionStorage.getItem('token');
    setToken(token);
    if (!token) {
      navigate('/');
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="Launcher">
      {!token ? (
        <Routes>
          <Route index element={<Login />} />
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      ) : (
        <div className="spin-wrapper">
          <Spin />
        </div>
      )}
    </div>
  );
}
