import { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message, Space } from 'antd';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import axios from '../../utils/axios';
import apis from './api';

const Login = () => {
  // const navigate = useNavigate();
  const [firstRender, setFirstRender] = useState(true);
  const [url, setUrl] = useState('');
  const [code, setCode] = useState('');
  const [platformName, setPlatformName] = useState('');
  const [icp, setIcp] = useState('');
  const [beian, setBeian] = useState('');
  const [disabled, setDisabled] = useState(false);

  const getCaptcha = async () => {
    const { data } = await axios.post(apis.validateCode);
    if (data.success) {
      let { base64, code } = data.data;
      setUrl(base64);
      setCode(code);
    }
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
    let { captcha } = values;
    if (captcha.toUpperCase() === code) {
      handleLogin(values);
    } else {
      message.error('验证码错误');
    }
  };

  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo);
  // };

  const handleLogin = async (obj) => {
    setDisabled(true);
    const { data } = await axios.post(apis.login, {
      accountName: obj.username,
      accountPwd: obj.password,
    });
    if (data.success) {
      sessionStorage.setItem('token', data.data.token);
      // navigate('/welcome');
      window.location.reload();
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    setFirstRender(false);
    if (!firstRender) {
      const getPlatform = async () => {
        let url = window.location.origin.includes('localhost')
          ? 'www.aaa.com'
          : window.location.origin;
        const { data } = await axios.post(apis.getPlatformByUrl, {
          pcUrl: url,
        });
        if (data.success) {
          sessionStorage.setItem('platformId', data.data.id);
        }
      };
      getPlatform();

      getCaptcha();

      if (window.location.origin.includes('fumaofuwu')) {
        setPlatformName('立派');
        setIcp('粤ICP备2023005065号-1');
        document.querySelector('title').innerText = '立派';
        document.querySelector('link[rel="icon"]').href = 'lipai.ico';
      } else {
        setPlatformName('久来米');
        setIcp('沪ICP备13007528号-2');
        setBeian('沪公网安备 31011502014561号');
        document.querySelector('title').innerText = '久来米';
        document.querySelector('link[rel="icon"]').href = 'favicon.ico';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstRender]);

  return (
    <div className="Login clearfix">
      <div className="pic"></div>
      <div className="form">
        <div className="inner">
          <p>欢迎登录{platformName}</p>
          <p>数字赋能 成就智慧企业</p>
          <Form
            name="basic"
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="账号"
              name="username"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              className="username"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="密码"
              name="password"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input.Password
                iconRender={(visible) =>
                  visible ? <EyeFilled /> : <EyeInvisibleFilled />
                }
              />
            </Form.Item>

            <Form.Item className="captcha">
              <Form.Item
                label="验证码"
                name="captcha"
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input maxLength={4} autoComplete="off" />
              </Form.Item>
              <Form.Item noStyle>
                <img src={url} alt="" onClick={getCaptcha} />
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit"
                disabled={disabled}
              >
                登录
              </Button>
            </Form.Item>
          </Form>

          <div className="icp">
            <Space>
              <span>{icp}</span>
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noreferrer"
              >
                {beian}
              </a>
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
